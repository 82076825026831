import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import clienteAxios from '../../config/axios';
import Token from '../../config/Token';

import {STATUS_SUCCEEDED, STATUS_LOADING, STATUS_FAILED, ERROR_LOGIN} from '../../config/Const';

export const fetchEquipos = createAsyncThunk(
    'equipos/fetchEquipos',
    async() => {
        var response = await clienteAxios.get('/Equipos/Get', Token());
        return response.data;
    },
);

export const fetchEquiposById = createAsyncThunk(
    'equipo/fetchEquiposById',
    async(id) => {
        var response = await clienteAxios.get(`/Equipos/GetById?Id=${id}`, Token());
        return response.data;
    },
);

export const fetchEquipo = createAsyncThunk(
    'equipos/fetchEquiposRegistrar',
    async(equipo) => {
        var response = await clienteAxios.post(`/Equipos/Registrar`, equipo, Token());
        return response.data;
    },
);

export const fetchEquipoDelete = createAsyncThunk(
    'equipos/fetchEquiposDelete',
    async(id) => {
        var response = await clienteAxios.delete(`/equipos/Delete?id=${id}`, Token());
        return response.data;
    },
);
const initialState = {
    equipos: [],
    equipo: null,
    status: '',
    register:null,
    error: null,
    delete: false,
    reset: undefined,
};

const equiposSlice = createSlice({
    name: 'equipos',
    initialState,
    reducers: {
        resetEquipos: (state, action) => {
            state.reset = action.payload;
        }
    },
    extraReducers:(builder) => {
        builder.addCase(fetchEquipos.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchEquipos.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;
            state.equipos = action.payload;
        }) 
        .addCase(fetchEquipos.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
            }
        });
        builder.addCase(fetchEquiposById.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchEquiposById.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.equipo = action.payload;
        }) 
        .addCase(fetchEquiposById.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
            }
        });
        builder.addCase(fetchEquipo.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchEquipo.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.register = action.payload;
            state.reset = true;
        }) 
        .addCase(fetchEquipo.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
               }
        });
        builder.addCase(fetchEquipoDelete.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchEquipoDelete.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.delete = action.payload;
            state.reset = true;
        }) 
        .addCase(fetchEquipoDelete.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
               }
        });
    },
});

export const {resetEquipos} = equiposSlice.actions;
export const selectEquipo = (state) => state.equipo;
export const selectEquipos = (state) => state.equipos;
export const getEquiposStatus = (state) => state.status;
export const getEquiposError = (state) => state.error;

export default equiposSlice.reducer;