import {useRouteError, Link} from 'react-router-dom';

const NotFound = ()=> {
    const error = useRouteError();

    return(
        <div>
            <h1>Error 404</h1>
            <p>Pagina no funciona</p>
            <p>{error.statusText || error.message}</p>
            <Link to="/">Volver al inicio</Link>
        </div>
    );
}

export default NotFound;