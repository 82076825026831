import React from 'react';

const Footer = () => {
    return(
       <div>
      <hr className="bg-orage" />
      <footer className="container col-lg-10 mt-3 mb-3 text-center">
        <p>
          Sistema de Información <b>H</b>
          <span>y</span>
          <b>M</b>
          <span >s</span>
        </p>
        <div>
          Copyright&copy; 2020 - Página creada por HyMs - Todos los derechos
          reservados
        </div>
        <span>
          Comunícate con nosotros a través de la dirección de correo:{" "}
          <b>facturareact@gmail.com</b>
        </span>
      </footer>
      <hr className="bg-orage" />
       </div>
    )
}

export default Footer;