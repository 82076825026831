import { isCellEditCommitKeys } from '@mui/x-data-grid/utils/keyboardUtils';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const Detalle = (ids) => {
    const equipos = useSelector((state) => state.documento.documento.documentoDetalles);
const [equipo, setEquipo] = useState({});

useEffect(() => {
    if(equipos && ids){
        setEquipo(equipos.filter(x => x.equipo.id === ids.id)[0].equipo);
        console.log(equipo);
    }
  }, [equipo]);

    return(
    <>
    {equipo ? (
                <main>
                <article>
                    <p><b>Nombre:</b> {equipo.label}</p>
                    <p><b>Descripcion:</b> {equipo.descripcion}</p>
                    <p><b>Certificado:</b> {equipo.certificado ? 'SI' : 'NO'}</p>
                    <p><b>Dimensiones:</b> {equipo.dimensiones}</p>
                    <p><b>Valor Unitario:</b> <span className='text-primary'>{equipo.valorUnitario}</span></p>  
                    <p><b>Cantidad en bodega:</b> <span className='text-primary'>{equipo.cantidad}</span></p>                 
                </article>
                </main>
    ) : null}

    </>
    );
};

export default Detalle;