import { useSelector } from 'react-redux';

const Detalle = () =>{
    const usuario = useSelector((state) => state.usuario.usuario);

    return(
    <>
    {usuario != null ? (
                <main>
                <article>
                    <p><b>Nombre:</b> {usuario.nombre}</p>
                    <p><b>Tipo Identificación:</b> {usuario.tipoIdentificacion}</p>
                    <p><b>Identificación:</b> {usuario.identificacion}</p>
                    <p><b>Telefono:</b> {usuario.telefono}</p>
                    <p><b>Email:</b> <span className='text-primary'>{usuario.email}</span></p>
                    <p><b>Dirección:</b> {usuario.direccion}</p>
                    <p><b>Tipo Usuario:</b> {usuario.tipoUsuario}</p>
                    <p><b>Fecha Registro:</b> {usuario.fechaCreacion}</p>           
                    <p><b>Usuario Registro:</b> {usuario.usuarioCreador}</p>
                    <p><b>Fecha Actualizacion:</b> {usuario.fechaActualizacion}</p>
                    <p><b>Usuario Actualización:</b> {usuario.usuarioActulizador}</p>
                    <p><b>Estado:</b> {usuario.estado}</p>
                </article>
                </main>
    ) : null}

    </>
    );
};

export default Detalle;