export const STATUS_SUCCEEDED = 'succeeded';
export const STATUS_LOADING = 'loading';
export const STATUS_FAILED = 'failed';
export const ERROR_LOGIN = 'Request failed with status code 401';
export const FORMATO_NUMERICO_OBRA = "000";
export const ITEMS = [
    {
        label: 'Activo',
        id: 0
    },
    {
        label: 'Inactivo',
        id: 1
    }
];
