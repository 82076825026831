import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import { useNavigate, NavLink } from 'react-router-dom';

//Matrial UI
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

//Import modal
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Detalle from './Detalle';
import { Puff } from 'react-loader-spinner';
import {
  fetchDocumentos,
  fetchDocumentosById,
  fetchDocumentoDelete,
  clearDocumento
} from '../../features/documentos/DocumentoSlice';

import {
  exitLogin
} from '../../features/usuarios/autenticationSlice';
import { red } from '@mui/material/colors';


const Documentos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const rows = useSelector((state) => state.documento.documentos);
  const reset = useSelector((state) => state.documento.reset);

  const columns = [
    { title: "id", field: "id" },
    { title: "numero", field: "numero", headerName: "NUMERO" },
    { title: "TipoDoc", field: "TipoDoc", headerName: "TIPO DOC", valueGetter: params => params.row.tipoDocumento.label },
    { title: "EstadoDoc", field: "EstadoDoc", headerName: "ESTADO DOC", valueGetter: params => params.row.estadoDocumento.label },
    { title: "obra", field: "obra", headerName: "OBRA", valueGetter: params => params.row.obra.label },
    { title: "cliente", field: "cliente", headerName: "CLIENTE", valueGetter: params => params.row.obra.usuario.label },
    { title: "telefonoCliente", field: "telefonoCliente", headerName: "TELEFONO", valueGetter: params => params.row.obra.usuario.telefono },
    { title: "direccionCliente", field: "direccionCliente", headerName: "DIR", valueGetter: params => params.row.obra.usuario.direccion },
    {
      field: 'update', headerName: '', renderCell: (params) => {
        return (
          <button
            className="btn btn-warning"
            onClick={(e) => onClickUpdate(params.id)}
            variant="contained"
            style={{ fontSize: 13, padding: ' 6px 15px' }}
          >
            Modificar
          </button>
        );
      }
    },
    {
      field: 'equipo', headerName: '', renderCell: (params) => {
        return (
          <button
            className="btn btn-success"
            onClick={(e) => onClickEquipos(params.id)}
            variant="contained"
            style={{ fontSize: 13, padding: ' 6px 15px' }}
          >
            Equipos
          </button>
        );
      }
    },
    {
      field: 'descargar', headerName: '', renderCell: (params) => {
        return (
          <button
            className="btn btn-secondary"
            onClick={(e) => onClickDescargar(params.id)}
            variant="contained"
            style={{ fontSize: 13, padding: ' 6px 15px' }}
          >
            Descargar
          </button>
        );
      }
    },
    {
      field: 'detail', headerName: '', renderCell: (params) => {
        return (
          <button
            className="btn btn-primary"
            onClick={(e) => onClickDetalle(params.id)}
            variant="contained"
            style={{ fontSize: 13, padding: ' 6px 15px' }}
          >
            Detalle
          </button>
        );
      }
    },
    {
      field: 'delete', headerName: '', renderCell: (params) => {
        return (
          <button
            className="btn btn-danger"
            onClick={(e) => onClickDelete(params.id)}
            variant="contained"
            style={{ fontSize: 13, padding: ' 6px 15px' }}
          >
            Eliminar
          </button>
        );
      }
    },
  ];

  const onClickDelete = (id) => {
    //Pregunta usuario
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Una Documento que se elimina no se puede recuperar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#ff9800",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.value) {
        //Pasar al action
        // const jumbotron = document.querySelector(".scroll");
        // jumbotron.scrollIntoView({ behavior: "smooth" });
        await dispatch(fetchDocumentoDelete(id));

        if (localStorage.getItem("token") === "undefined") {
          dispatch(exitLogin(false));
          navigate('/login');
          return;
        }

       await dispatch(fetchDocumentos());

        Swal.fire({
          title: "Documento",
          text: "Documento eliminado correctamente",
          icon: "success"
        });
        navigate('/documentos');
      }
    });
  };

  useEffect(() => {
    if (reset || reset === undefined) {
      dispatch(fetchDocumentos({ pagination: 1, recordsPorPage: 50 }));
    }
  }, [reset, dispatch]);

  const onClickUpdate = async (id) => {
    await dispatch(fetchDocumentosById(id));

    if (localStorage.getItem("token") === "undefined") {
      dispatch(exitLogin(false));
      navigate('/login');
      return;
    }
    navigate(`/documento/${id}`);
  };

  const onClickEquipos = async (id) => {
    await dispatch(fetchDocumentosById(id));

    //  if (localStorage.getItem("token") === "undefined") {
    //    dispatch(exitLogin(false));
    //    navigate('/login');
    //    return;
    //  }
    navigate(`/documentoDetalles/${id}`);
  };

  const onClickDetalle = async (id) => {
    await dispatch(fetchDocumentosById(id));
    if (localStorage.getItem("token") === "undefined") {
      dispatch(exitLogin(false));
      navigate('/login');
      return;
    }
    setOpen(true);
  };

  const onClickDescargar = async (id) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}Documentos/Descargar?id=${id}`; // URL del archivo a descargar
    try {
      window.location.href = url;
    } catch (error) {
      console.error('Error al descargar el archivo', error);
    }
  };

  const onClickCerrarPop = () => {
    setOpen(false);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -40%)'
  };

  const newDocumento = () => {
    dispatch(clearDocumento());
     navigate("/documento");
   }

  return (
    <>
      {
        rows === null ? (
          <Puff
            height="80"
            width="80"
            radius="9"
            color="#F4D03F"
            ariaLabel="loading"
            wrapperClass
          />
        ) : null
      }
      <h4 style={{textAlign:'center', color:'#6C757D'}}>LISTADO DE DOCUMENTOS</h4>
      {/* <NavLink className="navbar" to="/documento" style={{ color: 'white', textDecoration: 'inherit' }}><div className="row"><h6 className='text-primary col-xs-2'>Nuevo Documento</h6></div></NavLink> */}
      <NavLink className="navbar" to="/documento" onClick={(e) => newDocumento()} style={{ color: 'white', textDecoration: 'inherit' }}><div className="row"><h6 className='text-primary col-xs-2'>Nuevo Documento</h6></div></NavLink>
      <Box sx={{
        height: 400,
        width: '100%'
      }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
          columnVisibilityModel={{
            id: false,
          }}
        />
      </Box>
      <Modal isOpen={open} style={modalStyle}>
        <ModalHeader>
          <h2>Detalle de Documento</h2>
        </ModalHeader>
        <ModalBody>
          <Detalle />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={(e) => onClickCerrarPop()}>Cerrar</button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Documentos;