import { useState, useEffect, React } from "react";
import { useForm, } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLoaderData } from 'react-router-dom';
import Swal from "sweetalert2";

import {
    fetchObra
} from '../../../features/obras/obraSlice';

import {
    exitLogin
} from '../../../features/usuarios/autenticationSlice';
import AutocompleteUI from "../../global/AutocompleteUI";
import { obtenerConsecutivo } from "../../../services/configuracionService";

const Obra = () => {
    const obra = useSelector((state) => state.obra.obra);
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const usuariosResponse = useLoaderData();
    const [idUsuario, setIdUsuario] = useState(0);
    const [codigoObra, setCodigoObra] = useState("");

    const registerObra = async (data) => {
        if (obra != null) {
            data.id = obra.id;
            data.idUserActualizacion = Number(localStorage.getItem("userLogin"));
        } else {
            data.idUserCreate = Number(localStorage.getItem("userLogin"));
        }

        //data.certificado = getCertificado(data.certificado);
        data.activo = getEstado(data.estado);
        if (idUsuario === 0) {
            Swal.fire({
                title: "Obra?",
                text: "No ha seleccionado un usuario para la obra a crear",
                icon: "success"
            });
            return;
        }
        data.idUsuario = Number(idUsuario);
        var response = await dispatch(fetchObra(data));

        if (localStorage.getItem("token") === "undefined") {
            dispatch(exitLogin(false));
            navigate('/login');
            return;
        }

        switch (response.payload) {
            case 1:
                {
                    reset();
                    Swal.fire({
                        title: "Obra?",
                        text: obra != null ? "Obra actualizada exitosamente" : "Obra creada exitosamente",
                        icon: "success"
                    });
                    navigate("/Obras");
                    return;
                };

            case -1:
                {
                    Swal.fire({
                        title: "Obra?",
                        text: "Ya existe una obra con el el usuario y dirrección creada en el sistema",
                        icon: "warning"
                    });
                    return;
                };

            default: {
                Swal.fire({
                    title: "Obra?",
                    text: obra != null ? "No fue posible actualizar la obra ingresada" : "No fue posible crear la obra ingresada",
                    icon: "error"
                });
                return;
            }
        }
    }

    const getEstado = (value) => {
        switch (value) {
            case "Activo":
                return true;
            case "Inactivo":
                return false;
            default:
                return "";
        }
    }

    const getValueUsuario = (value) => {
        var id = value ? value.id : null;
        setIdUsuario(id);
    }

    useEffect(() => {
        if (obra != null) {
            setIdUsuario(obra.idUsuario);
            setValue("codigo", obra.codigo);
            reset(obra);
        }

        if (codigoObra === "" && obra === null) {
            (async () => {
                var response = await obtenerConsecutivo(1);
                setCodigoObra(response);
                setValue("codigo", response);
            })();
        }
    }, [obra, codigoObra]);

    return (
        <>
            <form onSubmit={handleSubmit(registerObra)}>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="form-group">
                            <b><label for="codigo">Codigo</label></b>
                            <input type="text" className="form-control border" id="codigo" {...register("codigo")} disabled />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <b><label for="direccion">Dirección</label></b>
                            <input type="text" className="form-control border" id="direccion" placeholder="Dirección de la obra" {...register("direccion", { required: true, minLength: 5, maxLength: 300 })} />
                            {errors.direccion?.type === "required" && (<p>❌ Campo requerido</p>)}
                            {errors.direccion?.type === "minLength" && (<p>❌ Este campo recibe minimo 10 caracteres</p>)}
                            {errors.direccion?.type === "maxLength" && (<p>❌ Este campo recibe miximo 200 caracteres</p>)}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="form-group">
                            <b><label for="descripcion">Descripcion</label></b>
                            <input type="text" className="form-control border" id="descripcion" placeholder="Descripción de la obra" {...register("descripcion", { required: true, minLength: 5, maxLength: 300 })} />
                            {errors.descripcion?.type === "required" && (<p>❌ Campo requerido</p>)}
                            {errors.descripcion?.type === "minLength" && (<p>❌ Este campo recibe minimo 10 caracteres</p>)}
                            {errors.descripcion?.type === "maxLength" && (<p>❌ Este campo recibe miximo 200 caracteres</p>)}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <b><label for="estado">Estado</label></b>
                        <select id="estado" className="form-control p-2" {...register("estado", { required: "select one option" })}>
                            <option selected value={""}></option>
                            <option value={"Activo"}>Activo</option>
                            <option value={"Inactivo"}>Inactivo</option>
                        </select>
                        {errors.estado?.type === "required" && (<p>❌ Campo requerido</p>)}
                    </div>
                    <div className="col-lg-6">
                        <b><label>Usuario</label></b>
                        <AutocompleteUI
                            props={usuariosResponse.fill}
                            title="Seleccionar"
                            valueId={ obra ? obra.idUsuario ? usuariosResponse.fill.filter(x => x.id === obra.idUsuario ) : null : null}
                            idContent={"920d6479-bf66-4121-aa7c-6022a514e234"}                            
                            getValue={getValueUsuario}
                            validate={true}
                        />
                    </div>
                </div>

                <button type="submit" className="btn btn-success">Guardar</button>
            </form>
        </>
    );
};

export default Obra;

