import clienteAxios from '../config/axios';
import Token from '../config/Token';
import {FORMATO_NUMERICO_OBRA} from '../config/Const';
import {loaderUsuarios} from './usuariosService';

export const obtenerConsecutivo = async (id) => {
    var response = await clienteAxios.get(`/Configuracion/GetById?id=${id}`, Token());
    if(!response)
    throw{
        //status: res.status,
        statusText: "No encontrado",
    };
    const configiracioneResponse = `${(response.data.valor + 1).toString()}`;
    return configiracioneResponse;
};

export const initialDataDocumento = async () => {
    var vehiculos = await clienteAxios.get(`/Configuracion/GetVehiculos`, Token());
    var tiposDocumentos = await clienteAxios.get(`/Configuracion/GetTiposDocumentos`, Token());
    var tiposEstadosDocumentos = await clienteAxios.get(`/Configuracion/GetTiposEstadosDocumentos`, Token());
    var usuarios = await clienteAxios.get(`/Usuarios/GetUsuarios`, Token());
    var obras = await clienteAxios.get(`/Obras/GetUsuarios`, Token());
    var numeroConsecutivo = await clienteAxios.get(`/Configuracion/GetNumeroConsecutivo`, Token())
  
    const responseDocumento = {
        vehiculos,
        tiposDocumentos,
        tiposEstadosDocumentos,
        usuarios,
        obras,
        numeroConsecutivo        
    } 
    return responseDocumento;
};