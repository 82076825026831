import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import clienteAxios from '../../config/axios';
import Token from '../../config/Token';

import {STATUS_SUCCEEDED, STATUS_LOADING, STATUS_FAILED, ERROR_LOGIN} from '../../config/Const';

export const fetchUsuarios = createAsyncThunk(
    'usuario/fetchUsuarios',
    async() => {
        var response = await clienteAxios.get('/Usuarios/Get', Token());       
        return response.data;
    },
);

export const fetchUsuariosById = createAsyncThunk(
    'usuario/fetchUsuariosById',
    async(id) => {
        var response = await clienteAxios.get(`/Usuarios/GetById?Id=${id}`, Token());
        return response.data;
    },
);

export const fetchUsuario = createAsyncThunk(
    'usuario/fetchUsuariosRegistrar',
    async(usuario) => {
        var response = await clienteAxios.post(`/Usuarios/Registrar`, usuario, Token());
        return response.data;
    },
);

export const fetchUsuarioDelete = createAsyncThunk(
    'usuario/fetchUsuariosDelete',
    async(id) => {
        var response = await clienteAxios.delete(`/Usuarios/Delete?id=${id}`, Token());
        return response.data;
    },
);

const initialState = {
    usuarios: [],
    usuario: null,
    register:null,
    status: '',
    error: null,
    delete: false,
    reset: undefined,
};

const usuarioSlice = createSlice({
    name: 'usuarios',
    initialState,
    reducers: {
        resetUsuarios: (state, action) => {
            state.reset = action.payload;
        },
        clearUsuario: (state) => {
            state.usuario = null;
        }
    },
    extraReducers:(builder) => {
        builder.addCase(fetchUsuarios.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchUsuarios.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.usuarios = action.payload;            
            state.reset = false;
        }) 
        .addCase(fetchUsuarios.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
           if(action.error.message === ERROR_LOGIN){
            localStorage.setItem("token", 'undefined');
           }
        });
        builder.addCase(fetchUsuariosById.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchUsuariosById.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.usuario = action.payload;
        }) 
        .addCase(fetchUsuariosById.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
               }
        });
        builder.addCase(fetchUsuario.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchUsuario.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.register = action.payload;
            state.reset = true;
        }) 
        .addCase(fetchUsuario.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
               }
        });
        builder.addCase(fetchUsuarioDelete.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchUsuarioDelete.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.delete = action.payload;
            state.reset = true;
        }) 
        .addCase(fetchUsuarioDelete.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
               }
        });
    },
});

export const {resetUsuarios, clearUsuario} = usuarioSlice.actions;
export const selectUsuario = (state) => state.usuario;
export const selectUsuarios = (state) => state.usuarios;
export const getUsuariosStatus = (state) => state.status;
export const getUsuariosError = (state) => state.error;

export default usuarioSlice.reducer;

