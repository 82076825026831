import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import { useNavigate, NavLink} from 'react-router-dom';

//Matrial UI
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

//Import modal
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Detalle from './Detalle';
import { Puff } from 'react-loader-spinner';
import {
  fetchDocumentosById,
    fetchDocumentoDetalleDelete
  } from '../../features/documentos/DocumentoSlice';

  import {
    exitLogin
  } from '../../features/usuarios/autenticationSlice';

  const DocumentoDetalles = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const [open, setOpen] = useState(false);
    const [idEquipo, setIdEquipo] = useState(0);
    const documento = useSelector((state) => state.documento.documento);
    const rows = useSelector((state) => state.documento.documento ? state.documento.documento.documentoDetalles : []);
    //const reset = useSelector((state) => state.documento.reset);

    const columns = [    
        { title: "id", field: "id" },
        { title: "equipo", field: "equipo", headerName: "Equipo", valueGetter: params => params.row.equipo.label, width: 300},
        { title: "cantidad", field: "cantidad", headerName: "Cantidad", valueGetter: params => params.row.cantidad.toLocaleString('es-CO') },
        { title: "valorUnitario", field: "valorUnitario", headerName: "Valor Unitario", valueGetter: params => params.row.valorUnitario.toLocaleString('es-CO', { style: "currency", currency: "COP" }) },
        { title: "valorSubTotal", field: "valorSubTotal", headerName: "Valor SubTotal", valueGetter: params => params.row.valorTotal.toLocaleString('es-CO', { style: "currency", currency: "COP" }), width: 150 },
        { field: 'update', headerName: '', renderCell: (params) => {
          return (
            <button
            className="btn btn-warning"
              onClick={(e) => onClickUpdate(params.row.equipo.id)}
              variant="contained"
              style={{fontSize:13, padding:' 6px 15px'}}
            >
              Modificar
            </button>
          );
        } },
        { field: 'detail', headerName: '', renderCell: (params) => {
          return (
            <button
            className="btn btn-primary"
              onClick={(e) => onClickDetalle(params.row.equipo.id)}
              variant="contained"
              style={{fontSize:13, padding:' 6px 15px'}}
            >
              Detalle
            </button>
          );
        } },
        { field: 'delete', headerName: '', renderCell: (params) => {
          return (
            <button
            className="btn btn-danger"
              onClick={(e) => onClickDelete(params.row.equipo.id)}
              variant="contained"
              style={{fontSize:13, padding:' 6px 15px'}}
            >
              Eliminar
            </button>
          );
        } },     
      ];    
  
      const onClickDelete = (id) => {    
        //Pregunta usuario
        Swal.fire({
          title: "¿Estas seguro?",
          text: "Una equipo que se elimina no se puede recuperar!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#ff9800",
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "Cancelar",
        }).then(async (result) => {
          if (result.value) {
            //Pasar al action
            // const jumbotron = document.querySelector(".scroll");
            // jumbotron.scrollIntoView({ behavior: "smooth" });
            console.log(id);
           await dispatch(fetchDocumentoDetalleDelete({idDocumento: documento.id, idEquipo: id})); 
  
            if (localStorage.getItem("token") === "undefined") {
              dispatch(exitLogin(false));
              navigate('/login');
              return;
            } 
            
            Swal.fire({
              title: "Documento",
              text: "Equipo eliminado correctamente",
              icon: "success"
            });
            await dispatch(fetchDocumentosById(documento.id));
            navigate(`/documentoDetalles/${documento.id}`);
          } 
        });  
      };
  
      useEffect(() => {
        // if (reset || reset === undefined) {   
        //   dispatch(fetchDocumentos({pagination:1,recordsPorPage:50}));
        // }
      }, []);
  
        const onClickUpdate = async (idEquipo) => {
         await dispatch(fetchDocumentosById(documento.id));
          if (localStorage.getItem("token") === "undefined") {
            dispatch(exitLogin(false));
            navigate('/login');
            return;
          }
          navigate(`/documentoDetalle/${documento.id}/${idEquipo}`);
        };
  
        const onClickDetalle = async (id) => {
         //await dispatch(fetchDocumentosById(id));
          if (localStorage.getItem("token") === "undefined") {
            dispatch(exitLogin(false));
            navigate('/login');
            return;
          }
          setIdEquipo(id);
          setOpen(true);
        };
        
        const onClickCerrarPop = () => {  
          setOpen(false);
        };

        const modalStyle = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -40%)'
        };
  
    return(
        <>
            {
rows === null ? (
  <Puff
  height="80"
  width="80"
  radius="9"
  color="#F4D03F"
  ariaLabel="loading"
  wrapperClass
/>
) : null
    }
    {documento ? ( <NavLink className="navbar" to={`/documentoDetalle/${documento.id}`} style={{ color: 'white', textDecoration: 'inherit'}}><div className="row"><h6 className='text-primary col-xs-2'>Nuevo Equipo</h6></div></NavLink>
) : null}
  <h4 style={{textAlign:'center', color:'#6C757D'}}>{documento.tipoDocumento.label} No. <span style={{ color: '#CCA505'}}>{documento.numero}</span> - Obra: <span style={{ color: '#CCA505'}}>{documento.obra.label}</span></h4>
         {/* <NavLink className="navbar" to="/documentos"  style={{ color: 'white', textDecoration: 'inherit'}}><div className="row"><h6 className='text-success col-xs-2'>Documentos</h6></div></NavLink> */}
      <Box sx={{
        height: 400,
        width: '100%'
      }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
          columnVisibilityModel={{
            id: false,
          }}          
        />
      </Box>
      <Modal isOpen={open} style={modalStyle}>
        <ModalHeader>
          <h2>Detalle de Equipo</h2>
        </ModalHeader>
        <ModalBody>
          <Detalle id={idEquipo}/>           
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary"  onClick={(e) => onClickCerrarPop()}>Cerrar</button>
        </ModalFooter>
      </Modal>
        </>
    );
};

export default DocumentoDetalles;

