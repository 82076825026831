import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "../components/NavBar";
import Foteer from "../pages/Foteer";

const LayoutPublic = () => {
    const navegation = useNavigate();
   return(
    <div className="nav-separator">
        <NavBar />
        <main className="container">
            {navegation.state === "loading" && (
                <div className="alert alert-info my-5">Loading...</div>
            )}
            <Outlet/>
        </main>
        <Foteer/>
    </div>
   );
};

export default LayoutPublic;