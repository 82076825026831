import {NavLink, Navigate, useNavigate} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';

import {
  exitLogin
} from '../features/usuarios/autenticationSlice';

import {resetUsuarios} from '../features/usuarios/usuarioSlice';
import { resetEquipos } from '../features/equipos/equipoSlice';
import { resetObras } from '../features/obras/obraSlice';
import { resetDocumentos } from '../features/documentos/DocumentoSlice';

import { jwtDecode } from 'jwt-decode';

const NavBar = () => {
  const [userName, setUserName] = useState('');
  const dispatch = useDispatch();   
  const navigate = useNavigate(); 
  const token = localStorage.getItem("token") ?  localStorage.getItem("token").toString() : '';

  const exitLoginUser = () => {
    dispatch(exitLogin(false));
    navigate("/login");
  };

  const onClickUsuarios = () => {
    dispatch(resetUsuarios(true));
  };

  const onClickEquipos = () => {
    dispatch(resetEquipos(true));
  };

  const onClickObras = () => {
    dispatch(resetObras(true));
  };

  const onClickDocumentos = () => {
    dispatch(resetDocumentos(true));
  };

  useEffect(() => {
    if(token != ''){
      const decoded = jwtDecode(token);
      setUserName(decoded.unique_name);
    }
  });

  //Offcanvas navbar para invetigar

    return(
        // <nav className='navbar navbar-dark bg-dark'>
        //     <NavLink to="/" className="btn btn-outline-primary">
        //         Home
        //     </NavLink>
        //     <NavLink to="/home" className="btn btn-outline-primary">
        //         Home
        //     </NavLink>
        //     <NavLink to="/blog" className="btn btn-outline-primary">
        //         Blog
        //     </NavLink>
        //     <NavLink to="/header" className="btn btn-outline-primary">
        //         Header
        //     </NavLink>
        //     <NavLink to="/email" className="btn btn-outline-primary">
        //         Email
        //     </NavLink>
        // </nav>
<Fragment>
  
<div className='nav-separator'>
<nav className="navbar fixed-top bg-secondary p-2">
  <div className="container-fluid">
    <NavLink className="navbar" to="/"  style={{ color: 'white', textDecoration: 'inherit'}}><div className="row"><h1 className='text-warning col-xs-2'>Hera</h1></div></NavLink>
   
    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="offcanvas offcanvas-end" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title text-primary text-decoration-none" id="offcanvasNavbarLabel">ANDAMIOS HERMANOS GARCIAS</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body ">   
        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
        <li>Usuario: <h6 style={{ color: '#CCA505'}}>{userName}</h6></li>
          <li className="nav-item" data-bs-dismiss="offcanvas">
            <NavLink className="nav-link text-dark" to="/documentos" style={{ color: 'inherit', textDecoration: 'inherit'}}><i className="fas fa-dollar-sign" onClick={() => onClickDocumentos()}></i> Documentos</NavLink>
          </li>
          {/* <li className="nav-item" data-bs-dismiss="offcanvas">
            <NavLink className="nav-link text-dark" target='_self'  to="/devolucion" style={{ color: 'inherit', textDecoration: 'inherit'}}><i className="far fa-file-excel"></i> Devoluciones</NavLink>
          </li>
          <li className="nav-item" data-bs-dismiss="offcanvas">     
            <NavLink className="nav-link text-dark"  to="/remision" style={{ color: 'inherit', textDecoration: 'inherit'}}><i className="far fa-address-card"></i> Remisiones</NavLink>
          </li> */}
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fas fa-certificate"></i> Configuración
            </a>
            <ul className="dropdown-menu">
              <li data-bs-dismiss="offcanvas"><NavLink className="dropdown-item text-dark" to="/equipos" style={{ color: 'inherit', textDecoration: 'inherit'}}><i className="fas fa-share-alt" onClick={() => onClickEquipos()}></i> Equipos</NavLink></li>
              <li data-bs-dismiss="offcanvas"><NavLink className="dropdown-item text-dark" to="/usuarios" style={{ color: 'inherit', textDecoration: 'inherit'}} onClick={() => onClickUsuarios()}><i className="fas fa-user"></i> Usuarios</NavLink></li>
              <li data-bs-dismiss="offcanvas"><NavLink className="dropdown-item text-dark" to="/obras" style={{ color: 'inherit', textDecoration: 'inherit'}} onClick={() => onClickObras()}><i className="far fa-edit"></i> Obras</NavLink></li>
            </ul>
          </li>
          <li className="nav-item" onClick={() => exitLoginUser()}>
            <NavLink className=" nav-link text-dark" to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}><i className="fas fa-sign-in-alt text-color"></i> {token == null ? 'Iniciar Sesión' : 'Cerrar Sesión'}</NavLink>
          </li>
          
        </ul>
      </div>
   
    </div>
  
  </div>

</nav>
</div>
</Fragment>
    );
};

export default NavBar;