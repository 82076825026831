//Import de librerias
import { Navigate } from "react-router-dom";

//Import propios
import Login from '../components/Login';
import Autocomplete from "../components/global/Autocomplete";

export default function PublicRoutes(){
    return[
        {
            path:"/login",
            element: <Login/>
        },
        // {
        //     path:"/auto",
        //     element: <Autocomplete/>
        // },
        {
            path: "", element: <Navigate to="/login" replace />
        },
    ];
}