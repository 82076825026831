import { useSelector } from 'react-redux';

const Detalle = () => {
    const documento = useSelector((state) => state.documento.documento);
    return(
    <>
    {documento != null ? (
                <main>
                <article>
                    
                    <p><b>Numero:</b> <span className='text-primary'> {documento.numero}</span></p>
                    <p><b>Observaciones:</b> {documento.observaciones}</p>
                    <p><b>Obra:</b> {documento.obra.label}</p>
                    <p><b>Nombre cliente:</b> {documento.obra.usuario.label}</p>
                    <p><b>Telefono cliente:</b> {documento.obra.usuario.telefono}</p>
                    <p><b>Direccion cliente:</b>{documento.obra.usuario.direccion}</p>
                    <p><b>Email cliente:</b> {documento.obra.usuario.email}</p>
                    <p><b>Tipo documento:</b> {documento.tipoDocumento.label}</p>
                    <p><b>Estado documento:</b> {documento.estadoDocumento.label}</p>
                    <p><b>Nombre transportador:</b> {documento.usuarioTransportador ? documento.usuarioTransportador.label: null}</p>
                    <p><b>Telefono transportador:</b> {documento.usuarioTransportador ? documento.usuarioTransportador.telefono: null}</p>
                    <p><b>Placa:</b> {documento.vehiculo ? documento.vehiculo.label: null}</p>
                    <p><b>Marca:</b> {documento.vehiculo ? documento.vehiculo.marca: null}</p>
                    <p><b>Tipo Vehiculo:</b> {documento.vehiculo ? documento.vehiculo.tipoVehiculo: null}</p>
                    <p><b>Entrega de equipo:</b> {documento.usuarioEntrega ? documento.usuarioEntrega.label: null}</p>
                    <p><b>Telefono entrega:</b> {documento.usuarioEntrega ? documento.usuarioEntrega.telefono: null}</p>
                    <p><b>Responsable equipo:</b> {documento.usuarioRecibe ? documento.usuarioRecibe.label: null}</p>
                    <p><b>Telefono de responsable:</b> {documento.usuarioRecibe ? documento.usuarioRecibe.telefono: null}</p>
                    <p><b>Fecha registro:</b> {documento.fechaCreacion}</p>           
                    <p><b>Usuario registro:</b> {documento.userCreacion ? documento.userCreacion.label : null}</p>
                    <p><b>Fecha actualizacion:</b> {documento.fechaActualizacion}</p>
                    <p><b>Usuario actualización:</b> {documento.userActualizacion ? documento.userActualizacion.label : null}</p>
                </article>
                </main>
    ) : null}

    </>
    );
};

export default Detalle;