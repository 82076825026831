import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Swal from "sweetalert2";

import {
  fetchUsuario
} from '../../../features/usuarios/usuarioSlice';

import {
  exitLogin
} from '../../../features/usuarios/autenticationSlice';

import { viewSpinner } from '../../../features/general/spinnerSlice';

const Usuario = () => {
  const usuario = useSelector((state) => state.usuario.usuario);
  const status = useSelector((state) => state.usuario.status);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const [tipoUsuarioTemp, setTipoUsuarioTemp] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const registerUser = async (data) => {
    dispatch(viewSpinner(true));

    if (usuario != null) {
      data.id = usuario.id;
      data.IdUserActualizacion = Number(localStorage.getItem("userLogin"));
    } else {
      data.IdUserCreate = Number(localStorage.getItem("userLogin"));
    }

    data.idTipoIdentificacion = getTipoIdenty(data.tipoIdentificacion);
    data.IdTipoUsuario = getTipoUser(data.tipoUsuario);
    data.activo = getEstado(data.estado);
    var response = await  dispatch(fetchUsuario(data));

    if (localStorage.getItem("token") === "undefined") {
      dispatch(exitLogin(false));
      navigate('/login');
      return;
    }

    switch(response.payload ){
      case 1:
        {
          reset();
          Swal.fire({
            title: "Usuario?",
            text: usuario != null ? "Usuario actualizado exitosamente" : "Usuario creado exitosamente",
            icon: "success"
          });
          navigate("/usuarios");
          return;
        };

        // case -1:
        //   {
        //     Swal.fire({
        //       title: "Usuario?",
        //       text: "Ya existe un usuario con el correo y tipo de usuarios ingresados",
        //       icon: "warning"
        //     });
        //     return;
        //   };

          default: {
            Swal.fire({
              title: "Usuario?",
              text: usuario != null ? "No fue posible actualizar el usuario ingresado" : "No fue posible crear el usuario ingresado",
              icon: "error"
            });
            return;
          }
    }
  }

  const getTipoIdenty = (value) => {
    switch (value) {
      case "CC":
        return 1;
      case "NIT":
        return 2;
      default:
        return "";
    }
  }

  const getTipoUser = (value) => {
    switch (value) {
      case "Cliente":
        return 1;
      case "Proveedor":
        return 2;
      case "Administrador":
        return 3;
      default:
        return "";
    }
  }

  const getEstado = (value) => {
    switch (value) {
      case "Activo":
        return true;
      case "Inactivo":
        return false;
      default:
        return "";
    }
  }

  const onChange = (e) => {
    setTipoUsuarioTemp(e.target.value);
  }

  useEffect(() => {
    if (usuario != null) {
      reset(usuario);
    }
  }, [usuario]);

  return (
    <>
      <form onSubmit={handleSubmit(registerUser)}>
        <div className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="nombre">Nombre</label></b>
              <input type="text" className="form-control border" id="nombre" placeholder="Digita tu nombre" {...register("nombre", { required: true, minLength: 10, maxLength: 200 })} />
              {errors.nombre?.type === "required" && (<p>❌ Campo requerido</p>)}
              {errors.nombre?.type === "minLength" && (<p>❌ Este campo recibe minimo 10 caracteres</p>)}
              {errors.nombre?.type === "maxLength" && (<p>❌ Este campo recibe miximo 200 caracteres</p>)}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="idTipoIdentificacion">Tipo Identificación</label></b>
              <select id="idTipoIdentificacion" className="form-control border mt-2 p-2" {...register("tipoIdentificacion", { required: "select one option" })}>
                <option selected value={""}></option>
                <option value={"CC"}>CC</option>
                <option value={"NIT"}>NIT</option>
              </select>
              {errors.idTipoIdentificacion?.type === "required" && (<p>❌ Campo requerido</p>)}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="identificacion">Identificación</label></b>
              <input type="text" className="form-control border" id="identificacion" placeholder="Solo digitos numericos" {...register("identificacion", { required: true, minLength: 6, maxLength: 11, pattern: /^[0-9]+$/ })} />
              {errors.identificacion?.type === "required" && (<p>❌ Campo requerido</p>)}
              {errors.identificacion?.type === "minLength" && (<p>❌ Este campo recibe minimo 6 caracteres</p>)}
              {errors.identificacion?.type === "maxLength" && (<p>❌ Este campo recibe miximo 11 caracteres</p>)}
              {errors.identificacion?.type === "pattern" && (<p>❌ Este campo recibe solo numeros</p>)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="telefono">Telefono</label></b>
              <input type="number" className="form-control border" id="telefono" placeholder="Solo digitos numericos" {...register("telefono", { required: true, minLength: 10, maxLength: 10, pattern: /^[0-9]+$/ })} />
              {errors.telefono?.type === "required" && (<p>❌ Campo requerido</p>)}
              {errors.telefono?.type === "minLength" && (<p>❌ Este campo recibe minimo 6 caracteres</p>)}
              {errors.telefono?.type === "maxLength" && (<p>❌ Este campo recibe miximo 11 caracteres</p>)}
              {errors.telefono?.type === "pattern" && (<p>❌ Este campo recibe solo numeros</p>)}

            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="email">Email</label></b>
              <input type="email" className="form-control border" id="email" placeholder="xxxx@gmail.com" {...register("email", { required: true, pattern: /[^@\s]+@[^@\s]+\.[^@\s]+/ })} />
              {errors.email?.type === "required" && (<p>❌ Campo requerido</p>)}
              {errors.email?.type === "pattern" && (<p>❌ Este campo es de tipo email</p>)}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="IdTipoUsuario">Tipo Usuario</label></b>
              <select id="IdTipoUsuario" className="form-control mt-2 p-2" {...register("tipoUsuario", { required: "select one option" })} onChange={onChange}>
                <option selected value={""}></option>
                <option value={"Cliente"}>Cliente</option>
                <option value={"Proveedor"}>Proveedor</option>
                <option value={"Administrador"}>Administrador</option>
              </select>
              {errors.IdTipoUsuario?.type === "required" && (<p>❌ Campo requerido</p>)}
            </div>
          </div>
        </div>
        <div className="row">
          {tipoUsuarioTemp === 'Administrador' ? (
            <div className="col-lg-4">
              <div className="form-group">
                <b><label for="password">Password</label></b>
                <input type="password" className="form-control border" id="password" placeholder="La contraseña debe tener mayusculas, minusculas y caracter especial" {...register("password", { pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/ })} />
                {errors.password?.type === "pattern" && (<p>❌ Debe contener al menos un número y una letra mayúscula y minúscula, y al menos 8 o más caracteres</p>)}
              </div>
            </div>
          ) : null}
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="direccion">Direccion</label></b>
              <input type="text" className="form-control border" id="direccion" placeholder="Boston calle 32..." {...register("direccion", { required: true })} />
              {errors.direccion?.type === "required" && (<p>❌ Campo requerido</p>)}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="estado">Estado</label></b>
              <select id="estado" className="form-control mt-2 p-2" {...register("estado", { required: "select one option" })}>
                <option selected value={""}></option>
                <option value={"Activo"}>Activo</option>
                <option value={"Inactivo"}>Inactivo</option>
              </select>
              {errors.estado?.type === "required" && (<p>❌ Campo requerido</p>)}
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-success">Guardar</button>
      </form>
    </>
  );
};

export default Usuario;