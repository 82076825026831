//Import del sistema
import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import userImg from '../img/user.png';
import Swal from 'sweetalert2';

//Importamos redux
import {
    getAutenticationStatus,
    fetchAutentications
  } from '../features/usuarios/autenticationSlice';

const Login = (props) => {
    const navigate  = useNavigate();
    const dispatch = useDispatch();    
    const autenticationStatus = useSelector(getAutenticationStatus);
    const autentication = useSelector((state) => state.autentication);
    const [user, guardarUser] = useState({
        email: '',
        password: ''
    });

    const {email, password} = user;

    const onChange = (e) => {
        guardarUser({
            ...user,
            [e.target.name] : e.target.value
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();

        //Validar Formulario
        if(email.trim() === ''){
            Swal.fire("*", "El campo email es obligatorio", "warning");
            return;
        }

        if(password.trim() === ''){
            Swal.fire("*", "El campo password es obligatorio", "warning");
            return;
        }
        //Verificar errors

        //Login de usuario implementado redux v19
        if (!autenticationStatus && user) {
            dispatch(fetchAutentications(user));
          }
    }

    useEffect(() => {
        if(autentication.autentication){
            navigate('/usuarios');
        }
         // eslint-disable-next-line
    },[autentication]);
    
    return(
       <div className="modal-dialog text-center mt-4 p-4">
           <div className="col-sm-12 main-section">
                <div className="modal-content">
                    <div className="col-12 user-img">
                        <img src={userImg} alt=""/>
                    </div>
                    <form 
                        className="col-12"
                        onSubmit={onSubmit}
                    >
                        <h5>Iniciar Sesión</h5>
                        <div className="form-group" id="user-group">
                            <input 
                                type="email"
                                name="email"
                                value={email}
                                onChange={onChange}
                                className="form-control"
                                placeholder="Ingresa tu Email" 
                            />
                        </div>
                       
                        <div className="form-group" id="contrasena-group">
                            <input 
                                type="password"
                                name="password"
                                value={password}
                                onChange={onChange}
                                className="form-control"
                                placeholder="Ingresa tu Contraseña" 
                            />
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary"
                        ><i className="fas fa-sign-in-alt"></i> Ingresar</button>
                    </form>
                    <div className="col-12">
                        <Link to="/recover" className="">Recordar contraseña</Link>
                    </div>
                </div>
           </div>
       </div>
    );
};

export default Login;