import { useState, useEffect, React } from "react";
import { useForm, } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLoaderData } from 'react-router-dom';
import Swal from "sweetalert2";

import {
    fetchDocumento    
} from '../../features/documentos/DocumentoSlice';

import {
    exitLogin
} from '../../features/usuarios/autenticationSlice';

import AutocompleteUI from "../global/AutocompleteUI";
import { obtenerConsecutivo } from "../../services/configuracionService";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';

const Documento = () => {
    const documento = useSelector((state) => state.documento.documento);
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialDataDocumento = useLoaderData();
    const [usuarioRecibe, setUsuarioRecibe] = useState(null);
    const [usuarioEntrega, setUsuarioEntrega] = useState(null);
    const [usuarioTransportador, setUsuarioTransportador] = useState(null);

    const [estadoDocumento, setEstadoDocumento] = useState(0);
    const [tipoDocumento, setTipoDocumento] = useState(0);
    const [vehiculo, setVehiculo] = useState(null);
    const [obra, setObra] = useState(null);
    const [fechaEntrega, setFechaEntrega] = useState(null);
    const [fechaRecibe, setFechaRecibe] = useState(null);

    const registerDocumento = async (data) => {
        var documentoReal = {}
        if (documento != null) {         
            documentoReal.id = data.id;
            documentoReal.IdUserActualizacion = Number(localStorage.getItem("userLogin"));
        } else {
            documentoReal.IdUserCreate = Number(localStorage.getItem("userLogin"));
        }
        //data.certificado = getCertificado(data.certificado);
        documentoReal.idEstadoDocumento = estadoDocumento;
        documentoReal.idTipoDocumento = tipoDocumento;
        documentoReal.idVehiculo = vehiculo;
        documentoReal.idEntregado = usuarioEntrega;
        documentoReal.idRecibido = usuarioRecibe;
        documentoReal.IdTransportador = usuarioTransportador;
        documentoReal.fechaEntrega = fechaEntrega;
        documentoReal.fechaRecibido = fechaRecibe;
        documentoReal.idObra = obra;
        documentoReal.numero = data.numero;
        documentoReal.observaciones = data.observaciones;
        
        // if (tipoDocumento === 0) {
        //     Swal.fire({
        //         title: "Documento?",
        //         text: "No ha seleccionado un cliente para el documento asociado",
        //         icon: "success"
        //     });
        //     return;
        // }
        //data.idCliente = Number(idCliente);
        var response = await dispatch(fetchDocumento(documentoReal));

        if (localStorage.getItem("token") === "undefined") {
            dispatch(exitLogin(false));
            navigate('/login');
            return;
        }

        switch (response.payload) {
            case 1:
                {
                    reset();
                    Swal.fire({
                        title: "Documento?",
                        text: documento != null ? "Documento actualizado exitosamente" : "Documento creado exitosamente",
                        icon: "success"
                    });
                    navigate("/documentos");
                    return;
                };

            // case -1:
            //   {
            //     Swal.fire({
            //       title: "Documento?",
            //       text: "Ya existe un Documento con el el usuario y dirrección creada en el sistema",
            //       icon: "warning"
            //     });
            //     return;
            //   };

            default: {
                Swal.fire({
                    title: "Documento?",
                    text: documento != null ? "No fue posible actualizar el Documento ingresada" : "No fue posible crear el Documento ingresada",
                    icon: "error"
                });
                return;
            }
        }
    }

    const getValueUsuarioEntrega = (value) => {
        var id = value ? value.id : null;
        setUsuarioEntrega(id);
    }

    const getValueUsuarioRecibe = (value) => {
        var id = value ? value.id : null;
        setUsuarioRecibe(id);
    }

    const getValueUsuarioTransportador = (value) => {
        var id = value ? value.id : null;
        setUsuarioTransportador(id);
    }

    const getValueEstadoDocumento = (value) => {
        var id = value ? value.id : null;
        setEstadoDocumento(id);
    }

    const getValueTipoDocumento = (value) => {
        var id = value ? value.id : null;
        setTipoDocumento(id);
    }

    const getValueVehiculo = (value) => {
        var id = value ? value.id : null;
        setVehiculo(id);
    }

    const getValueObra = (value) => {
        var id = value ? value.id : null;
        setObra(id);
    }

    // const getValueActivo = (value) => {
    //     var id = value ? value.id : null;
    //     setActivo(id);
    // }

    useEffect(() => {
        if (documento != null) {
            setFechaEntrega(dayjs(documento.fechaEntrega));
            setFechaRecibe(dayjs(documento.fechaRecibido));
            setTipoDocumento(documento.tipoDocumento ? documento.tipoDocumento.id : null);
            setEstadoDocumento(documento.estadoDocumento ? documento.estadoDocumento.id : null);
            setUsuarioEntrega(documento.usuarioEntrega ? documento.usuarioEntrega.id : null);
            setUsuarioRecibe(documento.usuarioRecibe ? documento.usuarioRecibe.id : null);
            setUsuarioTransportador(documento.usuarioTransportador ? documento.usuarioTransportador.id : null);
            setVehiculo(documento.vehiculo ? documento.vehiculo.id : null);
            setObra(documento.obra ? documento.obra.id : null);
            reset(documento);
        }
        if (initialDataDocumento.numeroConsecutivo) {
            // (async () => {
            //     var response = await obtenerConsecutivo(2);
            //     setNumero(response);
            //     setValue("numero", response);
            // })();
            setValue("numero", initialDataDocumento.numeroConsecutivo.data);
        }
    }, [documento]);

    return (
        <>
            <form onSubmit={handleSubmit(registerDocumento)}>
                <div className="row">
                    <div className="col-lg-4">
                        <b><label>Numero</label></b>
                        <TextField fullWidth id="numero" variant="outlined" {...register("numero")} disabled size='small' />
                    </div>
                    <div className="col-lg-4">
                        <b><label>Tipo Documento</label></b>
                        <AutocompleteUI                          
                            props={initialDataDocumento.tiposDocumentos.data}
                            title="Seleccionar"
                            valueId={documento ? documento.tipoDocumento ? documento.tipoDocumento.id ? initialDataDocumento.tiposDocumentos.data.filter(x => x.id === documento.tipoDocumento.id ) : null : null: null}
                            idContent="85a8265e-3b1e-4569-b855-66123e1325ef" 
                            getValue={getValueTipoDocumento}
                            validate={true} 
                            disabled= {documento ? true : false}                        
                            />
                    </div>
                    <div className="col-lg-4">
                        <b><label>Estado documento</label></b>
                        <AutocompleteUI
                            getValue={getValueEstadoDocumento}
                            props={initialDataDocumento.tiposEstadosDocumentos.data}
                            title="Seleccionar"
                            valueId={documento ? documento.estadoDocumento ? documento.estadoDocumento.id ? initialDataDocumento.tiposEstadosDocumentos.data.filter(x => x.id === documento.estadoDocumento.id )  : null : null : null}
                            idContent={"187be6d8-7a0e-4302-836d-37baaabc31d7"}
                            validate={true}  
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <b><label>Usuario Entrega</label></b>
                        <AutocompleteUI                            
                            props={initialDataDocumento.usuarios.data}
                            title="Seleccionar"
                            valueId={ documento ? documento.usuarioEntrega ? documento.usuarioEntrega.id ? initialDataDocumento.usuarios.data.filter(x => x.id === documento.usuarioEntrega.id )  : null: null: null}
                            idContent="8556ad4f-0af3-4139-8e2d-8a998eb61c78" 
                            getValue={getValueUsuarioEntrega}
                            validate={tipoDocumento === 2 ? false : true} 
                            />
                    </div>
                    <div className="col-lg-4">
                        <b><label>Usuario Recibe</label></b>
                        <AutocompleteUI
                            getValue={getValueUsuarioRecibe}
                            props={initialDataDocumento.usuarios.data}
                            title="Seleccionar"
                            valueId={documento ? documento.usuarioRecibe ? documento.usuarioRecibe.id ? initialDataDocumento.usuarios.data.filter(x => x.id === documento.usuarioRecibe.id )  : null: null : null}
                            idContent="bf0716bc-0600-422a-8698-aca3394d4965" 
                            validate={tipoDocumento === 2 ? false : true} 
                            />
                    </div>
                    <div className="col-lg-4">
                        <b><label>Usuario Transportador</label></b>
                        <AutocompleteUI                            
                            props={initialDataDocumento.usuarios.data}
                            title="Seleccionar"
                            valueId={documento ? documento.usuarioTransportador ? documento.usuarioTransportador.id ? initialDataDocumento.usuarios.data.filter(x => x.id === documento.usuarioTransportador.id )  : null : null : null}
                            idContent={"29178b30-4480-4d03-b4ec-6323f4fbf879"}
                            getValue={getValueUsuarioTransportador}
                            validate={tipoDocumento === 2 ? false : true} 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4">
                        <b><label>Vehiculo</label></b>
                        <AutocompleteUI
                            getValue={getValueVehiculo}
                            props={initialDataDocumento.vehiculos.data}
                            title="Seleccionar"
                            valueId={documento ? documento.vehiculo ? documento.vehiculo.id ? initialDataDocumento.vehiculos.data.filter(x => x.id === documento.vehiculo.id )  : null : null : null}
                            idContent="1871bd1d-c2f5-49dc-a2e7-66a4a66ca6a3"
                            //validate={tipoDocumento == 2 ? false : true} 
                        />
                    </div>
                    <div className="col-lg-4">
                            <b><label>Fecha Entrega</label></b>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={fechaEntrega}
                                    onChange={(newValue) => setFechaEntrega(newValue)} 
                                    slotProps={{ textField: { size: 'small', fullWidth: true, required: tipoDocumento === 2 ? false : true } }}    
                                    size='small'
                                />
                            </LocalizationProvider>
                    </div>
                    <div className="col-lg-4">
                            <b><label>Fecha Recibe</label></b>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={fechaRecibe}
                                    onChange={(newValue) => setFechaRecibe(newValue)} 
                                    slotProps={{ textField: { size: 'small', fullWidth: true, required: tipoDocumento === 2 ? false : true } }}                                      
                                    valueType="date time"
                                />
                            </LocalizationProvider>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        <b><label>Observación</label></b>
                        <TextField fullWidth id="Observaciones" label="Observación" variant="outlined" placeholder="Observacione" size='small' {...register("observaciones")}/>
                    </div>
                    <div className="col-lg-4">
                            <b><label for="estado">Obra</label></b>
                            <AutocompleteUI
                            getValue={getValueObra}
                            props={initialDataDocumento.obras.data}
                            title="Seleccionar"
                            valueId={documento ? documento.obra ? documento.obra.id ? initialDataDocumento.obras.data.filter(x => x.id === documento.obra.id )  : null : null : null}
                            idContent="566b6fec-75be-46bd-947f-cbef4b87ad98"
                            validate={true} 
                        />
                    </div>
                    {/* <div className="col-lg-4">
                            <b><label for="estado">Estado</label></b>
                            <AutocompleteUI
                            getValue={getValueActivo}
                            props={ITEMS}
                            title="Seleccionar"
                            vehiculo={ITEMS.id ? `${ITEMS.id} - ${ITEMS.nombre}` : ""}
                            idContent="079f1346-2e6c-4725-bad0-36b75064e059"
                            validate={true} 
                        />
                    </div> */}
                </div>
                <button type="submit" className="btn btn-success">Guardar</button>
            </form>
        </>
    );
}

export default Documento;

// const Documento = () => {
//     return(
//       <h1>Sara</h1>
//     );
//   }
//   export default Documento;