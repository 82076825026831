import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';
import './Autocomplete.css';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const objects = [
    {
        title: 'Todos',
        value: 0
    },
    {
        title: 'Develop',
        value: 1
    },
    {
        title: 'Frontend',
        value: 2
    }
];

const theme = createTheme({
    typography: {
      body1: {
        // fontSize: 22,
      },
    },
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& label': {
              fontSize: 18,
            },
          },
          input: {
            fontSize: 18,
          },
          listbox: {
            fontSize: 18,
          },
        },
      },
    },
  });
  
  const StyledAutocomplete = styled(Autocomplete)({
    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
      fontSize: 18,
      
    },
  });
  const Option = styled('li')({
    fontSize: 18
  });
  

const AutocompleteUI = ({ props, title, valueId, idContent, getValue, validate, disabled }) => {
    const [value, setValue] = useState(valueId ? valueId[0] : null);

    const defaultOptions =   {
        options: props.length > 0 ? props : [],
        getOptionLabel: (options) => options.label
    }

    const onChange = (event, newValue) => {
        setValue(newValue);
        getValue(newValue);
    }

    return (
        <ThemeProvider theme={theme}>
        <Autocomplete
           options={props}
           getOptionLabel={(options) => options.label}
            multiple={false}
            id={idContent}
            value={value}
            getOptionSelected={(option, values) =>
                option._id === values._id
            }
            // onChange={(event, newValue) => {
            //     getValue(newValue)
            // }}
            onChange={(event, newValue) => onChange(event, newValue)}
            renderInput={(params) => (
                <TextField {...params} 
                  variant="outlined"
                  required = {validate}
                  label={title}
                  />
                  
            )}
            size='small'
            disabled={disabled}
        />
        </ThemeProvider>
    );
}

export default AutocompleteUI;