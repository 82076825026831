import { useState, useEffect, React } from "react";
import { useForm, } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLoaderData, useParams } from 'react-router-dom';
import Swal from "sweetalert2";

import {
    fetchDocumento,
    fetchDocumentosById,
    fetchDocumentoDetalle
} from '../../features/documentos/DocumentoSlice';

import {
    exitLogin
} from '../../features/usuarios/autenticationSlice';

import AutocompleteUI from "../global/AutocompleteUI";
import { TextField } from '@mui/material';
import { isNumber } from "@mui/x-data-grid/internals";

const DocumentoDetalle = () => {
    const documento = useSelector((state) => state.documento.documento);
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { idEquipo } = useParams();
    const initialDataEquipo = useLoaderData();
    const [equipo, setEquipo] = useState({});
    const [value, setValueCantidad] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [documentoDetalles, setDocumentoDetalles] = useState([]);
    const registerDocumento = async (data) => {
        var documentoReal = {}
        if (documento != null) {
            documentoReal.IdUserActualizacion = Number(localStorage.getItem("userLogin"));
        } else {
            documentoReal.IdUserCreate = Number(localStorage.getItem("userLogin"));
        }
        documentoReal.valorUnitario = data.valorUnitario;
        documentoReal.valorTotal = data.valorTotal;
        documentoReal.Cantidad = data.cantidad;
        documentoReal.IdEquipo = equipo.id;
        documentoReal.IdDocumento = documento.id;

        var response = await dispatch(fetchDocumentoDetalle(documentoReal));

        if (localStorage.getItem("token") === "undefined") {
            dispatch(exitLogin(false));
            navigate('/login');
            return;
        }

        switch (response.payload) {
            case 1:
                {
                    reset();
                    Swal.fire({
                        title: "Documento?",
                        text: documento != null ? "Equipo actualizado exitosamente" : "Se agrego equipo al documento exitosamente",
                        icon: "success"
                    });
                    await dispatch(fetchDocumentosById(documento.id));
                    navigate(`/documentoDetalles/${documento.id}`);
                    return;
                };

                case -1:
                    {
                        reset();
                        Swal.fire({
                            title: "Documento?",
                            text: "El documento actualizar no existe",
                            icon: "warning"
                        });
                        await dispatch(fetchDocumentosById(documento.id));
                        navigate(`/documentoDetalles/${documento.id}`);
                        return;
                    };

                    case -2:
                        {
                            reset();
                            Swal.fire({
                                title: "Documento?",
                                text: "El equipo actualizar no existe",
                                icon: "warning"
                            });
                            await dispatch(fetchDocumentosById(documento.id));
                            navigate(`/documentoDetalles/${documento.id}`);
                            return;
                        };

                        case -3:
                            {
                                reset();
                                Swal.fire({
                                    title: "Documento?",
                                    text: "La cantidad ingresada es mayor que la existente",
                                    icon: "warning"
                                });
                                await dispatch(fetchDocumentosById(documento.id));
                                navigate(`/documentoDetalles/${documento.id}`);
                                return;
                            };

            default: {
                Swal.fire({
                    title: "Documento?",
                    text: documento != null ? "No fue posible actualizar el equipo del documento seleccionado" : "No fue posible crear el equipo en el documento ingresada",
                    icon: "error"
                });
                return;
            }
        }
    }

    const getValueEquipo = (value) => {
        setDisabled(value ? setDisabled(false) : setDisabled(true));
        setValue("valorUnitario", value ? value.valorUnitario : 0);
        setEquipo(value);
    }

    const handleChange = (event) => {
        setValueCantidad(event.target.value);
        setValue("valorTotal", event.target.value ? Number(event.target.value) * equipo.valorUnitario : 0);
    }

    useEffect(() => {
        if (documento && documento.documentoDetalles.length > 0 && idEquipo) {       
            setEquipo(documento.documentoDetalles.filter(x => x.equipo.id === Number(idEquipo))[0].equipo);
            setValue("valorUnitario", documento.documentoDetalles.filter(x => x.equipo.id === Number(idEquipo))[0].valorUnitario);
            setValueCantidad(documento.documentoDetalles.filter(x => x.equipo.id === Number(idEquipo))[0].cantidad);
            setValue("valorTotal", documento.documentoDetalles.filter(x => x.equipo.id === Number(idEquipo))[0].valorTotal);           
        }
    }, [documento]);

    return (
        <>
            <form onSubmit={handleSubmit(registerDocumento)}>
                <div className="row">
                    <div className="col-lg-8">
                        <b><label>Equipo</label></b>
                        <AutocompleteUI
                            props={initialDataEquipo.equipos.data}
                            title="Seleccionar"
                            valueId={idEquipo ? initialDataEquipo.equipos.data.filter(x => x.id === Number(idEquipo)) : null}
                            idContent="fa070cca-7a04-4a18-bc0e-526677a5b921"
                            getValue={getValueEquipo}
                            validate={true}
                        />
                    </div>
                    <div className="col-lg-4">
                        <b><label>Valor Unitario</label></b>
                        <TextField fullWidth id="valorUnitario" 
                            variant="outlined" 
                            {...register("valorUnitario")}   
                            disabled size='small' 
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <b><label>Cantidad</label></b>
                        <TextField type="number" fullWidth value={value} id="cantidad" variant="outlined" {...register("cantidad", { required: true, pattern: /^[0-9]+$/ })} size='small' onChange={handleChange} disabled={disabled} required />

                    </div>
                    <div className="col-lg-6">
                        <b><label>Valor Total</label></b>
                        <TextField fullWidth id="valorTotal" variant="outlined" size='small' {...register("valorTotal")} disabled />
                    </div>
                </div>
                <button type="submit" className="btn btn-success">Guardar</button>
            </form>
        </>
    );
}
export default DocumentoDetalle;