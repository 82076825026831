import React, { useEffect } from 'react';
//import {router} from './routes/index'
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
//import { addUser } from './features/userios/userSlice';
import PrivateRoutes from './routes/PrivateRoutes';
import PublicRoutes from './routes/PublicRoutes';
//import { Suspense } from "react";

function App() {
   const router = createBrowserRouter([
    localStorage.getItem("token") == 'null' ? {} : PrivateRoutes(),
    ...PublicRoutes(),
]);

  return (
      <div className="container">
       <RouterProvider router={router}/>  
      </div>
  );
}
// import PrivateRoutes from './routes/PrivateRoutes'
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import Cotizacion from './components/Cotizacion';
// import Login from './components/Login';

// function App() {
//   return (
//     <div className="App">
//         <Router>
//           <Routes>
//             <Route element={<PrivateRoutes />}>
//                 <Route element={<Cotizacion/>} path="/cotizar" exact/>
//             </Route>
//             <Route element={<Login/>} path="/login"/>
//           </Routes>
//       </Router>
//     </div>
//   );
// }

 export default App;
