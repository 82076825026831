import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import clienteAxios from '../../config/axios';
import Token from '../../config/Token';

import {STATUS_SUCCEEDED, STATUS_LOADING, STATUS_FAILED, ERROR_LOGIN} from '../../config/Const';

export const fetchObras = createAsyncThunk(
    'obras/fetchObras',
    async() => {
        var response = await clienteAxios.get('/Obras/Get', Token());
        return response.data;
    },
);

export const fetchObrasById = createAsyncThunk(
    'obras/fetchObrasById',
    async(id) => {
        var response = await clienteAxios.get(`/Obras/GetById?Id=${id}`, Token());
        return response.data;
    },
);

export const fetchObra = createAsyncThunk(
    'obras/fetchObrasRegistrar',
    async(obra) => {
        var response = await clienteAxios.post(`/Obras/Registrar`, obra, Token());
        return response.data;
    },
);

export const fetchObraDelete = createAsyncThunk(
    'obras/fetchObrasDelete',
    async(id) => {
        var response = await clienteAxios.delete(`/Obras/Delete?id=${id}`, Token());
        return response.data;
    },
);

const initialState = {
    obras: [],
    obra: null,
    status: '',
    register:null,
    error: null,
    delete: false,
    reset: undefined,
};

const obrasSlice = createSlice({
    name: 'obras',
    initialState,
    reducers: {
        resetObras: (state, action) => {
            state.reset = action.payload;
        }
    },
    extraReducers:(builder) => {
        builder.addCase(fetchObras.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchObras.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;
            state.obras = action.payload;
        }) 
        .addCase(fetchObras.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
            }
        });
        builder.addCase(fetchObrasById.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchObrasById.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.obra = action.payload;
        }) 
        .addCase(fetchObrasById.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
            }
        });
        builder.addCase(fetchObra.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchObra.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.register = action.payload;
            state.reset = true;
        }) 
        .addCase(fetchObra.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
               }
        });
        builder.addCase(fetchObraDelete.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchObraDelete.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.delete = action.payload;
            state.reset = undefined;
        }) 
        .addCase(fetchObraDelete.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
            }
        });
    },
});

export const {resetObras} = obrasSlice.actions;
export const selectObra = (state) => state.obra;
export const selectObras = (state) => state.obras;
export const getObrasStatus = (state) => state.status;
export const getObrasError = (state) => state.error;

export default obrasSlice.reducer;