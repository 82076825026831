import clienteAxios from '../config/axios';
import Token from '../config/Token';


export const initialDataEquipo = async () => {
    var equipos = await clienteAxios.get(`/Equipos/Get`, Token());
  
    const responseEquipo = {
        equipos,       
    } 
    return responseEquipo;
};