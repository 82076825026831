import axios from 'axios';

const clienteAxios =  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

clienteAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

clienteAxios.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshToken = localStorage.getItem('refreshToken');
                const { data } = await clienteAxios.post('Autorization/AutenticationRefresh', {
                    TokenExpirado: localStorage.getItem('token'),
                    refreshToken: refreshToken
                });

                localStorage.setItem('token', data.token);
                localStorage.setItem('refreshToken', data.refreshToken);

                originalRequest.headers.Authorization = `Bearer ${data.token}`;
                return clienteAxios(originalRequest);
            } catch (err) {
                console.log('Refresh token error', err);
                // Handle refresh token failure (e.g., logout user)
            }
        }
        return Promise.reject(error);
    }
);

export default clienteAxios;