import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Detalle = () => {
    const equipo = useSelector((state) => state.equipo.equipo);
    return(
    <>
    {equipo != null ? (
                <main>
                <article>
                    <p><b>Nombre:</b> {equipo.nombre}</p>
                    <p><b>Descripcion:</b> {equipo.descripcion}</p>
                    <p><b>Dimensiones:</b> {equipo.dimensiones}</p>
                    <p><b>Valor Unitario:</b> <span className='text-primary'>{equipo.valorUnitario}</span></p>                  
                    <p><b>Cantidad:</b> {equipo.cantidad}</p>
                    <p><b>Certificado:</b> <span className='text-primary'>{equipo.certificado}</span></p>
                    <p><b>Fecha Registro:</b> {equipo.fechaCreacion}</p>           
                    <p><b>Usuario Registro:</b> {equipo.usuarioCreador}</p>
                    <p><b>Fecha Actualizacion:</b> {equipo.fechaActualizacion}</p>
                    <p><b>Usuario Actualización:</b> {equipo.usuarioActulizador}</p>
                    <p><b>Estado:</b> {equipo.estado}</p>
                </article>
                </main>
    ) : null}

    </>
    );
};

export default Detalle;