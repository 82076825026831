import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Swal from "sweetalert2";

import {
  fetchEquipo
} from '../../../features/equipos/equipoSlice';

import {
  exitLogin
} from '../../../features/usuarios/autenticationSlice';

const Equipo = () => {
  const equipo = useSelector((state) => state.equipo.equipo);
  const { register, formState: { errors }, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const registerEquipo = async (data) => {

    if (equipo != null) {
      data.id = equipo.id;
      data.IdUserActualizacion = Number(localStorage.getItem("userLogin"));
    } else {
      data.IdUserCreate = Number(localStorage.getItem("userLogin"));
    }

    data.certificado = getCertificado(data.certificado);
    data.activo = getEstado(data.estado);
    var response = await dispatch(fetchEquipo(data));

    if (localStorage.getItem("token") === "undefined") {
      dispatch(exitLogin(false));
      navigate('/login');
      return;
    }

    switch (response.payload) {
      case 1:
        {
          reset();
          Swal.fire({
            title: "Equipo?",
            text: equipo != null ? "Equipo actualizado exitosamente" : "Equipo creado exitosamente",
            icon: "success"
          });
          navigate("/equipos");
          return;
        };

      case -1:
        {
          Swal.fire({
            title: "Equipo?",
            text: "Ya existe un equipo con el nombre ingresado",
            icon: "warning"
          });
          return;
        };

        case -4:
          {
            Swal.fire({
              title: "Equipo?",
              text: "Estas ingresado una devolucion con una cantidad de equipos mayor a la del invetario unicial",
              icon: "warning"
            });
            return;
          };

      default: {
        Swal.fire({
          title: "Equipo?",
          text: equipo != null ? "No fue posible actualizar el equipo ingresado" : "No fue posible crear el equipo ingresado",
          icon: "error"
        });
        return;
      }
    }
  }

  const getEstado = (value) => {
    switch (value) {
      case "Activo":
        return true;
      case "Inactivo":
        return false;
      default:
        return "";
    }
  }

  const getCertificado = (value) => {
    switch (value) {
      case "Si":
        return true;
      case "No":
        return false;
      default:
        return "";
    }
  }

  useEffect(() => {
    if (equipo != null) {
      reset(equipo);
    }
  }, [equipo]);

  return (
    <>
      <form onSubmit={handleSubmit(registerEquipo)}>
        <div className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="nombre">Nombre</label></b>
              <input type="text" className="form-control border" id="nombre" placeholder="Digita tu nombre" {...register("nombre", { required: true, minLength: 5, maxLength: 300 })} />
              {errors.nombre?.type === "required" && (<p>❌ Campo requerido</p>)}
              {errors.nombre?.type === "minLength" && (<p>❌ Este campo recibe minimo 5 caracteres</p>)}
              {errors.nombre?.type === "maxLength" && (<p>❌ Este campo recibe miximo 200 caracteres</p>)}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="cantidad">Valor Unitario</label></b>
              <input type="number" className="form-control border" id="valorUnitario" placeholder="Solo digitos numericos" {...register("valorUnitario", { required: true, pattern: /^[0-9]+$/ })} />
              {errors.valorUnitario?.type === "required" && (<p>❌ Campo requerido</p>)}
              {errors.valorUnitario?.type === "pattern" && (<p>❌ Este campo recibe solo numeros</p>)}
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="cantidad">Cantidad</label></b>
              <input type="number" className="form-control border" id="cantidad" placeholder="Solo digitos numericos" {...register("cantidad", { required: true, pattern: /^[0-9]+$/ })} />
              {errors.cantidad?.type === "required" && (<p>❌ Campo requerido</p>)}
              {errors.cantidad?.type === "pattern" && (<p>❌ Este campo recibe solo numeros</p>)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="certificado">Certificado</label></b>
              <select id="certificado" className="form-control mt-2 p-2" {...register("certificado", { required: "select one option" })} placeholder="Si el equipo es certificado">
                <option selected value={""}></option>
                <option value={"Si"}>Si</option>
                <option value={"No"}>No</option>
              </select>
              {errors.certificado?.type === "required" && (<p>❌ Campo requerido</p>)}
            </div>
          </div>
          <div className="col-lg-4">
                <div className="form-group">
                  <b><label for="dimensiones">Dimensiones</label></b>
                  <input type="text" className="form-control border" id="dimensiones" placeholder="Medidas del equipo" {...register("dimensiones")}/>
                </div>
              </div> 
          <div className="col-lg-4">
            <div className="form-group">
              <b><label for="estado">Estado</label></b>
              <select id="estado" className="form-control mt-2 p-2" {...register("estado", { required: "select one option" })}>
                <option selected value={""}></option>
                <option value={"Activo"}>Activo</option>
                <option value={"Inactivo"}>Inactivo</option>
              </select>
              {errors.estado?.type === "required" && (<p>❌ Campo requerido</p>)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <b><label for="descripcion">Descripcion</label></b>
              <input type="text" className="form-control border" id="descripcion" placeholder="Descripción de equipo" {...register("descripcion", { required: true })} />
              {errors.descripcion?.type === "required" && (<p>❌ Campo requerido</p>)}
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-success">Guardar</button>
      </form>
    </>
  );
};

export default Equipo;