import { configureStore } from '@reduxjs/toolkit';
import obraReducer from './features/obras/obraSlice';
import spinnerReducer from './features/general/spinnerSlice';
import usuarioReducer from './features/usuarios/usuarioSlice';
import equipoReducer from './features/equipos/equipoSlice';
import documentoReducer from './features/documentos/DocumentoSlice';
import autenticationReducer from './features/usuarios/autenticationSlice';

export const store  = configureStore({
    reducer: {
        obra: obraReducer,
        spinner: spinnerReducer,
        usuario: usuarioReducer,
        equipo: equipoReducer,
        documento: documentoReducer,
        autentication: autenticationReducer,
    }
});
