//Import de librerias
import {Navigate} from "react-router-dom";

//Import propios
import Devolucion from '../components/documentos/Devolucion';
import Remision from '../components/documentos/Remision';
import Equipos from '../components/configuraciones/equipos/Equipos';
import Equipo from '../components/configuraciones/equipos/Equipo';
import Usuarios from '../components/configuraciones/usuarios/Usuarios';
import Usuario from '../components/configuraciones/usuarios/Usuario';
import Obras from '../components/configuraciones/obras/Obras';
import Obra from '../components/configuraciones/obras/Obra';
import Documentos from '../components/documentos/Documentos';
import Documento from '../components/documentos/Documento';
import Post from '../pages/Post';

import LayoutPublic from '../layout/LayoutPublic';
import NotFound from '../pages/NotFound';
import {loaderUsuarios} from '../services/usuariosService';
import {initialDataDocumento} from '../services/configuracionService';
import {initialDataEquipo} from '../services/equipoService';
import DocumentoDetalles from "../components/documento-detalle/DocumentoDetalles";
import DocumentoDetalle from "../components/documento-detalle/DocumentoDetalle";

export default function PrivateRoutes() {
  return {
    element: <LayoutPublic />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <Post />
      },
      {
        path: "/devolucion",
        element: <Devolucion />
      },
      {
        path: "/remision",
        element: <Remision />
      },
      {
        path: "/equipos",
        element: <Equipos />
      },
      {
        path: "/equipo",
        element: <Equipo />
      },
      {
        path: "/usuarios",
        element: <Usuarios />
      },
      {
        path: "/usuario",
        element: <Usuario />,
        //loader: loaderBlog
      },
      {
        path: "/obras",
        element: <Obras />
      },
      {
        path: "/obra",
        element: <Obra />,
        loader: loaderUsuarios
      },
      {
        path: "/obra/:id",
        element: <Obra />,
        loader: loaderUsuarios
      },
      {
        path: "/documentos",
        element: <Documentos />
      },
      {
        path: "/documento",
        element: <Documento/>,
        loader: initialDataDocumento
      },
      {
        path: "/documento/:id",
        element: <Documento />,
        loader: initialDataDocumento
      },
      {
        path: "/documentoDetalles/:id",
        element: <DocumentoDetalles />,
        loader: initialDataDocumento
      },
      {
        path: "/documentoDetalle/:id",
        element: <DocumentoDetalle />,
        loader: initialDataEquipo
      },
      {
        path: "/documentoDetalle/:idDocumento/:idEquipo",
        element: <DocumentoDetalle />,
        loader: initialDataEquipo
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      }
    ],
  }
}

