import React, { useEffect } from 'react';

const Remision = () => {
return(
    <>
        <p>Remision</p>
    </>
)
};

export default Remision;