import clienteAxios from '../config/axios';
import Token from '../config/Token';

// export const loaderUsuarios = async () => {
//     var response = await clienteAxios.get('/Usuarios/Get', Token());
//     if(!response)
//     throw{
//         //status: res.status,
//         statusText: "No encontrado",
//     };
    
//     const usuariosResponse = response.data;
//     return usuariosResponse;
// };

export const loaderUsuarios = async ({params}) => {
    var response = await clienteAxios.get('/Usuarios/GetUsuarios', Token());
    if(!response)
    throw{
        //status: res.status,
        statusText: "No encontrado",
    };

    var usuariosResponseFill = response.data;
    if(params && params.id){
        var responseById = await clienteAxios.get(`/Obras/GetByUsuarioId?id=${Number(params.id)}`, Token());
        if(!responseById)
        throw{
            //status: res.status,
            statusText: "No encontrado",
        };
        
        var usuariosResponseId = responseById.data;
    }

    const usuariosResponse = {
        fill: usuariosResponseFill,
        usuario: usuariosResponseId ? usuariosResponseId : ""
        
    } 
    return usuariosResponse;
};