import {createSlice }from '@reduxjs/toolkit';

const initialState = {
    view: true
  };

  export const spinnerSlice = createSlice({
    name: "spinner/fetchSpinner",
    initialState,
    reducers: {      
        viewSpinner:(state, action) => {
            state.view = action.payload;
      }
    },
  });

export const { viewSpinner } = spinnerSlice.actions;
export default spinnerSlice.reducer;

