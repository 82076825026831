import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import clienteAxios from '../../config/axios';

import { jwtDecode } from "jwt-decode";

export const fetchAutentications = createAsyncThunk(
    'autentications/fetchAutentications',
    async(usuario) => {
        var response = await clienteAxios.post('/Autorization/Autentication', usuario);
        return response.data;
    },
);

const initialState = {
    autentication: false,
    status: '',
    error: null,
};

const autenticationsSlice = createSlice({
    name: 'autentications',
    initialState,
    reducers: {
        exitLogin: (state, action) => {
            localStorage.setItem("token", null);
            state.autentication = action.payload;
        }
    },
    extraReducers:(builder) => {
        builder.addCase(fetchAutentications.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchAutentications.fulfilled, (state, action) => {
            state.status = 'succeeded';            
            state.autentication = true;
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem("refreshToken", action.payload.refreshToken);
            localStorage.setItem("userLogin", jwtDecode(action.payload.token).nameid);
        }) 
        .addCase(fetchAutentications.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    },
});

export const {exitLogin } = autenticationsSlice.actions;
export const selectAutentication = (state) => state.autentication;
export const getAutenticationStatus = (state) => state.status;
export const getAutenticationError = (state) => state.error;

export default autenticationsSlice.reducer;

