import { useSelector } from 'react-redux';

const Detalle = () => {
    const obra = useSelector((state) => state.obra.obra);

    return(
    <>
    {obra != null ? (
                <main>
                <article>
                    <p><b>Nombre:</b> {obra.codigo}</p>
                    <p><b>Descripcion:</b> {obra.descripcion}</p>
                    <p><b>Direccion:</b> {obra.direccion}</p>
                    <p><b>Cliente:</b> {obra.nombre}</p>
                    <p><b>Identificacion Usuario:</b> <span className='text-primary'>{obra.identificacion}</span></p>
                    <p><b>Telefono Usuario:</b> {obra.telefono}</p>
                    <p><b>Fecha Registro:</b> {obra.fechaCreacion}</p>           
                    <p><b>Usuario Registro:</b> {obra.usuarioCreador}</p>
                    <p><b>Fecha Actualizacion:</b> {obra.fechaActualizacion}</p>
                    <p><b>Usuario Actualización:</b> {obra.usuarioActulizador}</p>
                    <p><b>Estado:</b> {obra.estado}</p>
                </article>
                </main>
    ) : null}

    </>
    );
};

export default Detalle;