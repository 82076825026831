import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import clienteAxios from '../../config/axios';
import Token from '../../config/Token';

import {STATUS_SUCCEEDED, STATUS_LOADING, STATUS_FAILED, ERROR_LOGIN} from '../../config/Const';

export const fetchDocumentos = createAsyncThunk(
    'documentos/fetchDocumentos',
    async(filter) => {
        var response = await clienteAxios.get(`/Documentos/Get?Pagination=${filter.pagination}&RecordsPorPage=${filter.recordsPorPage}`, Token());
        return response.data;
    },
);

export const fetchDocumentosById = createAsyncThunk(
    'Documentos/fetchDocumentosById',
    async(id) => {
        var response = await clienteAxios.get(`/Documentos/GetById?Id=${id}`, Token());
        return response.data;
    },
);

export const fetchDocumento = createAsyncThunk(
    'Documentos/fetchDocumentosRegistrar',
    async(documento) => {
        var response = await clienteAxios.post(`/Documentos/Registrar`, documento, Token());
        return response.data;
    },
);

export const fetchDocumentoDetalle = createAsyncThunk(
    'DocumentosDetalles/fetchDocumentosDetallesRegistrar',
    async(documento) => {
        var response = await clienteAxios.post(`/Documentos/RegistrarDetalle`, documento, Token());
        return response.data;
    },
);

export const fetchDocumentoDelete = createAsyncThunk(
    'Documentos/fetchDocumentosDelete',
    async(id) => {
        var response = await clienteAxios.delete(`/Documentos/Delete?id=${id}`, Token());
        return response.data;
    },
);

export const fetchDocumentoDetalleDelete = createAsyncThunk(
    'Documentos/fetchDocumentosDetalleDelete',
    async(parameter) => {
        var response = await clienteAxios.delete(`/Documentos/DeleteDocumentoDetalle?idDocumento=${parameter.idDocumento}&idEquipo=${parameter.idEquipo}`, Token());
        return response.data;
    },
);


const initialState = {
    documentos: [],
    documento: null,
    status: '',
    registerDetalle:null,
    error: null,
    delete: false,
    deleteDetalle: false,
    reset: undefined,
    resetDetalle: undefined
};

const DocumentosSlice = createSlice({
    name: 'documentos',
    initialState,
    reducers: {
        resetDocumentos: (state, action) => {
            state.reset = action.payload;
        },
        clearDocumento: (state) => {
            state.documento = null;
        }
    },
    extraReducers:(builder) => {
        builder.addCase(fetchDocumentos.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchDocumentos.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;
            state.documentos = action.payload;
        }) 
        .addCase(fetchDocumentos.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
            }
        });
        builder.addCase(fetchDocumentosById.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchDocumentosById.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.documento = action.payload;
        }) 
        .addCase(fetchDocumentosById.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
            }
        });
        builder.addCase(fetchDocumento.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchDocumento.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.register = action.payload;
            state.documento = null;
            state.reset = true;
        }) 
        .addCase(fetchDocumento.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
               }
        });
        builder.addCase(fetchDocumentoDetalle.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchDocumentoDetalle.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.registerDetalle = action.payload;
            state.resetDetalle = true;
        }) 
        .addCase(fetchDocumentoDetalle.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
               }
        });
        builder.addCase(fetchDocumentoDelete.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchDocumentoDelete.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.delete = action.payload;
            state.reset = true;
        }) 
        .addCase(fetchDocumentoDelete.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
            }
        });
        builder.addCase(fetchDocumentoDetalleDelete.pending, (state) => {
            state.status = STATUS_LOADING;
        })
        .addCase(fetchDocumentoDetalleDelete.fulfilled, (state, action) => {
            state.status = STATUS_SUCCEEDED;            
            state.deleteDetalle = action.payload;
            state.resetDetalle = undefined;
        }) 
        .addCase(fetchDocumentoDetalleDelete.rejected, (state, action) => {
            state.status = STATUS_FAILED;
            state.error = action.error.message;
            if(action.error.message === ERROR_LOGIN){
                localStorage.setItem("token", 'undefined');
            }
        });
    },
});

export const {resetDocumentos, clearDocumento} = DocumentosSlice.actions;
export const selectDocumento = (state) => state.documento;
export const selectDocumentos = (state) => state.documentos;
export const getDocumentosStatus = (state) => state.status;
export const getDocumentosError = (state) => state.error;

export default DocumentosSlice.reducer;