import React, { useEffect } from 'react';

const Devolucion = () => {
return(
    <>
        <p>Devolucion</p>
    </>
)
};

export default Devolucion;