import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Swal from "sweetalert2";
import { useNavigate, NavLink } from 'react-router-dom';

//Matrial UI
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

//Import modal
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

import {
    fetchUsuarios,
    fetchUsuariosById,
    fetchUsuarioDelete,
    clearUsuario
  } from '../../../features/usuarios/usuarioSlice';

  import {
    exitLogin
  } from '../../../features/usuarios/autenticationSlice';

import Detalle from './Detalle';
import { Puff } from 'react-loader-spinner';

const Usuarios = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const [open, setOpen] = useState(false);
    const rows = useSelector((state) => state.usuario.usuarios);
    const reset = useSelector((state) => state.usuario.reset);

    const columns = [    
      { title: "id", field: "id" },
      { title: "identificacion", field: "identificacion", headerName: "IDENTIFI" },
      { title: "tipoIdentificacion", field: "tipoIdentificacion", headerName: "TIP. IDENTI", width: 50, description: "CC, NIT, Pasaporte, CE" },
      { title: "Nombre", field: "nombre", headerName: "NOMBRE", width: 300 },
      { title: "Dirección", field: "direccion", headerName: "DIRECCIÓN", width: 200},
      { title: "Rol", field: "tipoUsuario", headerName: "TIP. USUARIO", description: "Rol dentro del sistema"},
      { title: "Estado", field: "estado", headerName: "ESTADO" },
      { field: 'update', headerName: '', renderCell: (params) => {
        return (
          <button
          className="btn btn-warning"
            onClick={(e) => onClickUpdate(params.id)}
            variant="contained"
            style={{fontSize:13}}
          >
            Modificar
          </button>
        );
      } },
      { field: 'detail', headerName: '', renderCell: (params) => {
        return (
          <button
          className="btn btn-primary"
            onClick={(e) => onClickDetalle(params.id)}
            variant="contained"
            style={{fontSize:13}}
          >
            Detalle
          </button>
        );
      } },
      { field: 'delete', headerName: '', renderCell: (params) => {
        return (
          <button
          className="btn btn-danger"
            onClick={(e) => onClickDelete(params.id)}
            variant="contained"
            style={{fontSize:13}}
          >
            Eliminar
          </button>
        );
      } },      
    ];    

    const onClickDelete = (id) => {
      //Pregunta usuario
      Swal.fire({
        title: "¿Estas seguro?",
        text: "Un perfil que se elimina no se puede recuperar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#ff9800",
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.value) {
          //Pasar al action
          // const jumbotron = document.querySelector(".scroll");
          // jumbotron.scrollIntoView({ behavior: "smooth" });
         await dispatch(fetchUsuarioDelete(id)); 

          if (localStorage.getItem("token") === "undefined") {
            dispatch(exitLogin(false));
            navigate('/login');
            return;
          } 
          
          Swal.fire({
            title: "Usuario",
            text: "Usuario eliminado correctamente",
            icon: "success"
          });
        }


      });


    };

    useEffect(() => {  
        if (reset || reset === undefined) {
          dispatch(fetchUsuarios());
        }
      }, [reset, dispatch]);

      const onClickUpdate = async (id) => {
       await dispatch(fetchUsuariosById(id));
       
        if (localStorage.getItem("token") === "undefined") {
          dispatch(exitLogin(false));
          navigate('/login');
          return;
        }


        // dispatch(getIdAction(id));
        // //Mover la pantalla hacia arriba
        // const jumbotron = document.querySelector(".scroll");
        // jumbotron.scrollIntoView({ behavior: "smooth" });
        // history.push(`/perfil/${id}`);
        navigate('/usuario');
      };

      const onClickDetalle = async (id) => {
       await dispatch(fetchUsuariosById(id));
        if (localStorage.getItem("token") === "undefined") {
          dispatch(exitLogin(false));
          navigate('/login');
          return;
        }
        setOpen(true);
      };

      const onClickCerrarPop = () => {  
        setOpen(false);
      };

      const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -40%)'
      };

      const newUsuario = () => {
       dispatch(clearUsuario());
        navigate("/usuario");
      }

return(
    <>
    {
rows === null ? (
  <Puff
  height="80"
  width="80"
  radius="9"
  color="#F4D03F"
  ariaLabel="loading"
  wrapperClass
/>
) : null
    }
<h4 style={{textAlign:'center', color:'#6C757D'}}>LISTADO DE USUARIOS</h4>
     <NavLink className="navbar" onClick={(e) => newUsuario()}  style={{ color: 'white', textDecoration: 'inherit'}}><div className="row"><h6 className='text-primary col-xs-2'>Nuevo usuario</h6></div></NavLink>
      <Box sx={{
        height: 400,
        width: '100%'
      }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
          columnVisibilityModel={{
            id: false,
          }}          
        />
      </Box>
      <Modal isOpen={open} style={modalStyle}>
        <ModalHeader>
          <h2>Detalle de usuario</h2>
        </ModalHeader>
        <ModalBody>
          <Detalle/>           
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary"  onClick={(e) => onClickCerrarPop()}>Cerrar</button>
        </ModalFooter>
      </Modal>
    </>
)
};

export default Usuarios;